define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-text-editor", ["exports", "@ember/component", "@ember/object/computed", "@ember/runloop", "jquery", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "@ember/template-factory"], function (_exports, _component, _computed, _runloop, _jquery, _decorators, _I18n, _wizard, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-editor
    value=this.value
    forcePreview=this.forcePreview
    placeholder=this.placeholder
  }}
  
  <div class="wizard-editor-gutter">
    {{#if this.previewEnabled}}
      {{d-button
        action=(action "togglePreview")
        translatedLabel=this.previewLabel
      }}
    {{/if}}
  
    {{#if this.fieldsEnabled}}
      {{d-button
        action=(action "togglePopover")
        translatedLabel=this.popoverLabel
      }}
  
      {{#if this.showPopover}}
        <div class="wizard-editor-gutter-popover">
          <label>
            {{i18n "admin.wizard.action.post_builder.user_properties"}}
            {{this.userPropertyList}}
          </label>
  
          {{#if this.hasWizardFields}}
            <label>
              {{i18n "admin.wizard.action.post_builder.wizard_fields"}}
              {{this.wizardFieldList}}
            </label>
          {{/if}}
  
          {{#if this.hasWizardActions}}
            <label>
              {{i18n "admin.wizard.action.post_builder.wizard_actions"}}
              {{this.wizardActionList}}
            </label>
          {{/if}}
        </div>
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "7j7csxHY",
    "block": "[[[1,[28,[35,0],null,[[\"value\",\"forcePreview\",\"placeholder\"],[[30,0,[\"value\"]],[30,0,[\"forcePreview\"]],[30,0,[\"placeholder\"]]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"wizard-editor-gutter\"],[12],[1,\"\\n\"],[41,[30,0,[\"previewEnabled\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"action\",\"translatedLabel\"],[[28,[37,3],[[30,0],\"togglePreview\"],null],[30,0,[\"previewLabel\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"fieldsEnabled\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"action\",\"translatedLabel\"],[[28,[37,3],[[30,0],\"togglePopover\"],null],[30,0,[\"popoverLabel\"]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showPopover\"]],[[[1,\"      \"],[10,0],[14,0,\"wizard-editor-gutter-popover\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"admin.wizard.action.post_builder.user_properties\"],null]],[1,\"\\n          \"],[1,[30,0,[\"userPropertyList\"]]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasWizardFields\"]],[[[1,\"          \"],[10,\"label\"],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"admin.wizard.action.post_builder.wizard_fields\"],null]],[1,\"\\n            \"],[1,[30,0,[\"wizardFieldList\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasWizardActions\"]],[[[1,\"          \"],[10,\"label\"],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"admin.wizard.action.post_builder.wizard_actions\"],null]],[1,\"\\n            \"],[1,[30,0,[\"wizardActionList\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[13]],[],false,[\"d-editor\",\"if\",\"d-button\",\"action\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-text-editor.hbs",
    "isStrictMode": false
  });
  const excludedUserProperties = ["profile_background", "card_background"];
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNames: "wizard-text-editor",
    barEnabled: true,
    previewEnabled: true,
    fieldsEnabled: true,
    hasWizardFields: (0, _computed.notEmpty)("wizardFieldList"),
    hasWizardActions: (0, _computed.notEmpty)("wizardActionList"),
    didReceiveAttrs() {
      this._super(...arguments);
      if (!this.barEnabled) {
        (0, _runloop.scheduleOnce)("afterRender", this, this._hideButtonBar);
      }
    },
    _hideButtonBar() {
      (0, _jquery.default)(this.element).find(".d-editor-button-bar").addClass("hidden");
    },
    previewLabel(forcePreview) {
      return _I18n.default.t("admin.wizard.editor.preview", {
        action: _I18n.default.t(`admin.wizard.editor.${forcePreview ? "hide" : "show"}`)
      });
    },
    popoverLabel(showPopover) {
      return _I18n.default.t("admin.wizard.editor.popover", {
        action: _I18n.default.t(`admin.wizard.editor.${showPopover ? "hide" : "show"}`)
      });
    },
    userPropertyList() {
      return _wizard.userProperties.filter(f => !excludedUserProperties.includes(f)).map(f => ` u{${f}}`);
    },
    wizardFieldList(wizardFields) {
      return (wizardFields || []).map(f => ` w{${f.id}}`);
    },
    wizardActionList(wizardActions) {
      return (wizardActions || []).map(a => ` w{${a.id}}`);
    },
    actions: {
      togglePreview() {
        this.toggleProperty("forcePreview");
      },
      togglePopover() {
        this.toggleProperty("showPopover");
      }
    }
  }, [["method", "previewLabel", [(0, _decorators.default)("forcePreview")]], ["method", "popoverLabel", [(0, _decorators.default)("showPopover")]], ["method", "userPropertyList", [(0, _decorators.default)()]], ["method", "wizardFieldList", [(0, _decorators.default)("wizardFields")]], ["method", "wizardActionList", [(0, _decorators.default)("wizardActions")]]])));
});