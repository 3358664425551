define("discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/category-custom-settings/custom-wizard-category-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{i18n "admin.wizard.category_settings.custom_wizard.title"}}</h3>
  
  <section class="field new-topic-wizard">
    <label for="new-topic-wizard">
      {{i18n "admin.wizard.category_settings.custom_wizard.create_topic_wizard"}}
    </label>
    <div class="controls">
      {{combo-box
        value=this.wizardListVal
        content=this.wizardList
        onChange=(action "changeWizard")
        options=(hash none="admin.wizard.select")
      }}
    </div>
  </section>
  */
  {
    "id": "/CxfPvjH",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,0],[\"admin.wizard.category_settings.custom_wizard.title\"],null]],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"field new-topic-wizard\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"new-topic-wizard\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"admin.wizard.category_settings.custom_wizard.create_topic_wizard\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"value\",\"content\",\"onChange\",\"options\"],[[30,0,[\"wizardListVal\"]],[30,0,[\"wizardList\"]],[28,[37,2],[[30,0],\"changeWizard\"],null],[28,[37,3],null,[[\"none\"],[\"admin.wizard.select\"]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"combo-box\",\"action\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/category-custom-settings/custom-wizard-category-settings.hbs",
    "isStrictMode": false
  });
});