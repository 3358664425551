define("discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-api", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-wizard-controls">
    {{combo-box
      value=this.apiName
      content=this.apiList
      onChange=(route-action "changeApi")
      options=(hash none="admin.wizard.api.select")
    }}
  
    {{d-button
      action=(route-action "createApi")
      label="admin.wizard.api.create"
      icon="plus"
    }}
  </div>
  
  <div class="admin-wizard-container">
    {{outlet}}
  </div>
  */
  {
    "id": "nQHb68JL",
    "block": "[[[10,0],[14,0,\"admin-wizard-controls\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"content\",\"onChange\",\"options\"],[[30,0,[\"apiName\"]],[30,0,[\"apiList\"]],[28,[37,1],[\"changeApi\"],null],[28,[37,2],null,[[\"none\"],[\"admin.wizard.api.select\"]]]]]]],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"action\",\"label\",\"icon\"],[[28,[37,1],[\"createApi\"],null],\"admin.wizard.api.create\",\"plus\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-wizard-container\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"combo-box\",\"route-action\",\"hash\",\"d-button\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-api.hbs",
    "isStrictMode": false
  });
});