define("discourse/plugins/discourse-custom-wizard/discourse/templates/custom-wizard-step", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.stepMessage}}
    <div class="step-message {{this.stepMessage.state}}">
      <div class="text">
        {{this.stepMessage.text}}
      </div>
      {{#if this.showReset}}
        <a role="button" class="reset-wizard" {{action "resetWizard"}}>
          {{i18n "wizard.reset"}}
        </a>
      {{/if}}
    </div>
  {{/if}}
  {{#if this.step.permitted}}
    {{custom-wizard-step
      step=this.step
      wizard=this.wizard
      goNext=(action "goNext")
      goBack=(action "goBack")
      showMessage=(action "showMessage")
    }}
  {{/if}}
  */
  {
    "id": "nATsEfWt",
    "block": "[[[41,[30,0,[\"stepMessage\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"step-message \",[30,0,[\"stepMessage\",\"state\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"text\"],[12],[1,\"\\n      \"],[1,[30,0,[\"stepMessage\",\"text\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"showReset\"]],[[[1,\"      \"],[11,3],[24,\"role\",\"button\"],[24,0,\"reset-wizard\"],[4,[38,1],[[30,0],\"resetWizard\"],null],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"wizard.reset\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"step\",\"permitted\"]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"step\",\"wizard\",\"goNext\",\"goBack\",\"showMessage\"],[[30,0,[\"step\"]],[30,0,[\"wizard\"]],[28,[37,1],[[30,0],\"goNext\"],null],[28,[37,1],[[30,0],\"goBack\"],null],[28,[37,1],[[30,0],\"showMessage\"],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"action\",\"i18n\",\"custom-wizard-step\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/custom-wizard-step.hbs",
    "isStrictMode": false
  });
});