define("discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <PluginOutlet @name="admin-wizards-top" @connectorTagName="div" />
  
  {{#admin-nav}}
    {{nav-item route="adminWizardsWizard" label="admin.wizard.nav_label"}}
    {{nav-item
      route="adminWizardsCustomFields"
      label="admin.wizard.custom_field.nav_label"
    }}
    {{nav-item
      route="adminWizardsSubmissions"
      label="admin.wizard.submissions.nav_label"
    }}
    {{#if this.showApi}}
      {{nav-item route="adminWizardsApi" label="admin.wizard.api.nav_label"}}
    {{/if}}
    {{nav-item route="adminWizardsLogs" label="admin.wizard.log.nav_label"}}
    {{nav-item
      route="adminWizardsManager"
      label="admin.wizard.manager.nav_label"
    }}
  
    <div class="admin-actions">
      <WizardSubscriptionStatus />
    </div>
  {{/admin-nav}}
  
  <div class="admin-container">
    {{outlet}}
  </div>
  */
  {
    "id": "yGbg554c",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@connectorTagName\"],[\"admin-wizards-top\",\"div\"]],null],[1,\"\\n\\n\"],[6,[39,1],null,null,[[\"default\"],[[[[1,\"  \"],[1,[28,[35,2],null,[[\"route\",\"label\"],[\"adminWizardsWizard\",\"admin.wizard.nav_label\"]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"route\",\"label\"],[\"adminWizardsCustomFields\",\"admin.wizard.custom_field.nav_label\"]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"route\",\"label\"],[\"adminWizardsSubmissions\",\"admin.wizard.submissions.nav_label\"]]]],[1,\"\\n\"],[41,[30,0,[\"showApi\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"route\",\"label\"],[\"adminWizardsApi\",\"admin.wizard.api.nav_label\"]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[28,[35,2],null,[[\"route\",\"label\"],[\"adminWizardsLogs\",\"admin.wizard.log.nav_label\"]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"route\",\"label\"],[\"adminWizardsManager\",\"admin.wizard.manager.nav_label\"]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"admin-actions\"],[12],[1,\"\\n    \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"plugin-outlet\",\"admin-nav\",\"nav-item\",\"if\",\"wizard-subscription-status\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards.hbs",
    "isStrictMode": false
  });
});