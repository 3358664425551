define("discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-logs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-wizard-select admin-wizard-controls">
    {{combo-box
      value=this.wizardId
      content=this.wizardList
      onChange=(route-action "changeWizard")
      options=(hash none="admin.wizard.select")
    }}
  </div>
  
  {{wizard-message
    key=this.messageKey
    opts=this.messageOpts
    url=this.documentationUrl
    component="logs"
  }}
  
  <div class="admin-wizard-container">
    {{outlet}}
  </div>
  */
  {
    "id": "9GyrwoXZ",
    "block": "[[[10,0],[14,0,\"admin-wizard-select admin-wizard-controls\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"content\",\"onChange\",\"options\"],[[30,0,[\"wizardId\"]],[30,0,[\"wizardList\"]],[28,[37,1],[\"changeWizard\"],null],[28,[37,2],null,[[\"none\"],[\"admin.wizard.select\"]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"key\",\"opts\",\"url\",\"component\"],[[30,0,[\"messageKey\"]],[30,0,[\"messageOpts\"]],[30,0,[\"documentationUrl\"]],\"logs\"]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-wizard-container\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"combo-box\",\"route-action\",\"hash\",\"wizard-message\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-logs.hbs",
    "isStrictMode": false
  });
});