define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-upload", ["exports", "@ember/component", "@ember/object", "@ember/owner", "@ember/service", "discourse/lib/uppy/uppy-upload", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _object, _owner, _service, _uppyUpload, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.wrapperClass}}>
    <input
      {{did-insert this.setup}}
      disabled={{this.uppyUpload.uploading}}
      id={{this.inputId}}
      class="hidden-upload-field"
      type="file"
      accept={{this.field.file_types}}
      style="visibility: hidden; position: absolute;"
    />
    <DButton
      @translatedLabel={{this.uploadLabel}}
      @translatedTitle={{this.uploadLabel}}
      @icon="upload"
      @disabled={{this.uppyUpload.uploading}}
      @action={{this.chooseFiles}}
      class="wizard-btn wizard-btn-upload-file"
    />
    {{#if this.field.value}}
      {{#if this.isImage}}
        <img src={{this.field.value.url}} class="wizard-image-preview" />
      {{else}}
        {{this.field.value.original_filename}}
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "gqdCRD3p",
    "block": "[[[10,0],[15,0,[30,0,[\"wrapperClass\"]]],[12],[1,\"\\n  \"],[11,\"input\"],[16,\"disabled\",[30,0,[\"uppyUpload\",\"uploading\"]]],[16,1,[30,0,[\"inputId\"]]],[24,0,\"hidden-upload-field\"],[16,\"accept\",[30,0,[\"field\",\"file_types\"]]],[24,5,\"visibility: hidden; position: absolute;\"],[24,4,\"file\"],[4,[38,0],[[30,0,[\"setup\"]]],null],[12],[13],[1,\"\\n  \"],[8,[39,1],[[24,0,\"wizard-btn wizard-btn-upload-file\"]],[[\"@translatedLabel\",\"@translatedTitle\",\"@icon\",\"@disabled\",\"@action\"],[[30,0,[\"uploadLabel\"]],[30,0,[\"uploadLabel\"]],\"upload\",[30,0,[\"uppyUpload\",\"uploading\"]],[30,0,[\"chooseFiles\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"field\",\"value\"]],[[[41,[30,0,[\"isImage\"]],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[30,0,[\"field\",\"value\",\"url\"]]],[14,0,\"wizard-image-preview\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"field\",\"value\",\"original_filename\"]]],[1,\"\\n\"]],[]]]],[]],null],[13]],[],false,[\"did-insert\",\"d-button\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-upload.hbs",
    "isStrictMode": false
  });
  class CustomWizardFieldUpload extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    setup() {
      this.uppyUpload = new _uppyUpload.default((0, _owner.getOwner)(this), {
        id: this.inputId,
        type: `wizard_${this.field.id}`,
        uploadDone: upload => {
          this.setProperties({
            "field.value": upload,
            isImage: this.imageUploadFormats.includes(upload.extension)
          });
          this.done();
        }
      });
      this.uppyUpload.setup(document.getElementById(this.inputId));
    }
    static #_2 = (() => dt7948.n(this.prototype, "setup", [_object.action]))();
    get imageUploadFormats() {
      return this.siteSettings.wizard_recognised_image_upload_formats.split("|");
    }
    get inputId() {
      return `wizard_field_upload_${this.field?.id}`;
    }
    get wrapperClass() {
      let result = "wizard-field-upload";
      if (this.isImage) {
        result += " is-image";
      }
      if (this.fieldClass) {
        result += ` ${this.fieldClass}`;
      }
      return result;
    }
    uploadLabel() {
      return this.uppyUpload?.uploading ? `${_discourseI18n.default.t("wizard.uploading")} ${this.uppyUpload.uploadProgress}%` : _discourseI18n.default.t("wizard.upload");
    }
    static #_3 = (() => dt7948.n(this.prototype, "uploadLabel", [(0, _decorators.default)("uppyUpload.uploading", "uppyUpload.uploadProgress")]))();
    chooseFiles() {
      this.uppyUpload?.openPicker();
    }
    static #_4 = (() => dt7948.n(this.prototype, "chooseFiles", [_object.action]))();
  }
  _exports.default = CustomWizardFieldUpload;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CustomWizardFieldUpload);
});