define("discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/admin-user-details/admin-user-wizard-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="details">
    <h1>{{i18n "admin.wizard.user.label"}}</h1>
  
    <div class="display-row">
      <div class="field">{{i18n "admin.wizard.user.redirect.label"}}</div>
      <div class="value">
        {{#if @model.redirect_to_wizard}}
          <LinkTo
            @route="adminWizardsWizardShow"
            @model={{dasherize @model.redirect_to_wizard}}
          >
            {{@model.redirect_to_wizard}}
          </LinkTo>
        {{else}}
          &mdash;
        {{/if}}
      </div>
      <div class="controls">
        {{#if @model.redirect_to_wizard}}
          <DButton
            @action={{fn @model.clearWizardRedirect @model}}
            @label="admin.wizard.user.redirect.remove_label"
            @title="admin.wizard.user.redirect.remove_title"
          />
        {{/if}}
      </div>
    </div>
  </section>
  */
  {
    "id": "FvHo3nNa",
    "block": "[[[10,\"section\"],[14,0,\"details\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,0],[\"admin.wizard.user.label\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"display-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"field\"],[12],[1,[28,[35,0],[\"admin.wizard.user.redirect.label\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n\"],[41,[30,1,[\"redirect_to_wizard\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"adminWizardsWizardShow\",[28,[37,3],[[30,1,[\"redirect_to_wizard\"]]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,1,[\"redirect_to_wizard\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        —\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n\"],[41,[30,1,[\"redirect_to_wizard\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@action\",\"@label\",\"@title\"],[[28,[37,5],[[30,1,[\"clearWizardRedirect\"]],[30,1]],null],\"admin.wizard.user.redirect.remove_label\",\"admin.wizard.user.redirect.remove_title\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@model\"],false,[\"i18n\",\"if\",\"link-to\",\"dasherize\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/admin-user-details/admin-user-wizard-details.hbs",
    "isStrictMode": false
  });
});