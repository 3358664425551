define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <CustomWizardComposerEditor
    @field={{this.field}}
    @composer={{this.composer}}
    @wizard={{this.wizard}}
    @fieldClass={{this.fieldClass}}
    @groupsMentioned={{this.groupsMentioned}}
    @cannotSeeMention={{this.cannotSeeMention}}
    @importQuote={{this.importQuote}}
    @togglePreview={{this.togglePreview}}
    @afterRefresh={{this.afterRefresh}}
  />
  
  <div class="bottom-bar">
    <DButton
      @action={{this.togglePreview}}
      class="wizard-btn toggle-preview"
      @label={{this.togglePreviewLabel}}
    />
  
    {{#if this.field.char_counter}}
      {{wizard-char-counter this.field.value this.field.max_length}}
    {{/if}}
  </div>
  */
  {
    "id": "Y+m5FGaw",
    "block": "[[[8,[39,0],null,[[\"@field\",\"@composer\",\"@wizard\",\"@fieldClass\",\"@groupsMentioned\",\"@cannotSeeMention\",\"@importQuote\",\"@togglePreview\",\"@afterRefresh\"],[[30,0,[\"field\"]],[30,0,[\"composer\"]],[30,0,[\"wizard\"]],[30,0,[\"fieldClass\"]],[30,0,[\"groupsMentioned\"]],[30,0,[\"cannotSeeMention\"]],[30,0,[\"importQuote\"]],[30,0,[\"togglePreview\"]],[30,0,[\"afterRefresh\"]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"bottom-bar\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"wizard-btn toggle-preview\"]],[[\"@action\",\"@label\"],[[30,0,[\"togglePreview\"]],[30,0,[\"togglePreviewLabel\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"field\",\"char_counter\"]],[[[1,\"    \"],[1,[28,[35,3],[[30,0,[\"field\",\"value\"]],[30,0,[\"field\",\"max_length\"]]],null]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"custom-wizard-composer-editor\",\"d-button\",\"if\",\"wizard-char-counter\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    showPreview: false,
    classNameBindings: [":wizard-field-composer", "showPreview:show-preview:hide-preview"],
    init() {
      this._super(...arguments);
      this.set("composer", _object.default.create({
        loading: false,
        reply: this.get("field.value") || "",
        afterRefresh: () => {}
      }));
    },
    setField() {
      this.set("field.value", this.get("composer.reply"));
    },
    togglePreviewLabel(showPreview) {
      return showPreview ? "wizard_composer.hide_preview" : "wizard_composer.show_preview";
    },
    importQuote() {},
    groupsMentioned() {},
    afterRefresh() {},
    cannotSeeMention() {},
    showUploadSelector() {},
    togglePreview() {
      this.toggleProperty("showPreview");
    }
  }, [["method", "setField", [(0, _decorators.observes)("composer.reply")]], ["method", "togglePreviewLabel", [(0, _decorators.default)("showPreview")]], ["method", "importQuote", [_object.action]], ["method", "groupsMentioned", [_object.action]], ["method", "afterRefresh", [_object.action]], ["method", "cannotSeeMention", [_object.action]], ["method", "showUploadSelector", [_object.action]], ["method", "togglePreview", [_object.action]]])));
});