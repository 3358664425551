define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-composer-editor", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/string", "discourse/components/composer-editor", "discourse/components/modal/insert-hyperlink", "discourse/lib/uploads", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _computed, _service, _string, _composerEditor, _insertHyperlink, _uploads, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.wizardComposerEdtiorEventPrefix = _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.fieldClass}}>
    <DEditor
      @tabindex={{this.field.tabindex}}
      @value={{this.composer.reply}}
      @placeholderOverride={{this.replyPlaceholder}}
      @previewUpdated={{this.previewUpdated}}
      @markdownOptions={{this.markdownOptions}}
      @extraButtons={{this.extraButtons}}
      @importQuote={{this.importQuote}}
      @showUploadModal={{this.showUploadModal}}
      @togglePreview={{this.togglePreview}}
      @validation={{this.validation}}
      @loading={{this.composer.loading}}
      @showLink={{this.showLink}}
      @wizardComposer={{true}}
      @fieldId={{this.field.id}}
      @disabled={{this.disableTextarea}}
      @outletArgs={{hash composer=this.composer editorType="composer"}}
    />
  
    <input
      type="file"
      id={{this.uppyComposerUpload.fileUploadElementId}}
      class="wizard-composer-upload"
      accept={{this.allowedFileTypes}}
      multiple
    />
  </div>
  */
  {
    "id": "1XsjFzuj",
    "block": "[[[10,0],[15,0,[30,0,[\"fieldClass\"]]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@tabindex\",\"@value\",\"@placeholderOverride\",\"@previewUpdated\",\"@markdownOptions\",\"@extraButtons\",\"@importQuote\",\"@showUploadModal\",\"@togglePreview\",\"@validation\",\"@loading\",\"@showLink\",\"@wizardComposer\",\"@fieldId\",\"@disabled\",\"@outletArgs\"],[[30,0,[\"field\",\"tabindex\"]],[30,0,[\"composer\",\"reply\"]],[30,0,[\"replyPlaceholder\"]],[30,0,[\"previewUpdated\"]],[30,0,[\"markdownOptions\"]],[30,0,[\"extraButtons\"]],[30,0,[\"importQuote\"]],[30,0,[\"showUploadModal\"]],[30,0,[\"togglePreview\"]],[30,0,[\"validation\"]],[30,0,[\"composer\",\"loading\"]],[30,0,[\"showLink\"]],true,[30,0,[\"field\",\"id\"]],[30,0,[\"disableTextarea\"]],[28,[37,1],null,[[\"composer\",\"editorType\"],[[30,0,[\"composer\"]],\"composer\"]]]]],null],[1,\"\\n\\n  \"],[10,\"input\"],[15,1,[30,0,[\"uppyComposerUpload\",\"fileUploadElementId\"]]],[14,0,\"wizard-composer-upload\"],[15,\"accept\",[30,0,[\"allowedFileTypes\"]]],[14,\"multiple\",\"\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"d-editor\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-composer-editor.hbs",
    "isStrictMode": false
  });
  const wizardComposerEdtiorEventPrefix = _exports.wizardComposerEdtiorEventPrefix = "wizard-editor";
  class CustomWizardComposerEditor extends _composerEditor.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    allowUpload = true;
    showLink = false;
    topic = null;
    showToolbar = true;
    focusTarget = "reply";
    canWhisper = false;
    lastValidatedAt = "lastValidatedAt";
    popupMenuOptions = [];
    draftStatus = "null";
    static #_2 = (() => dt7948.g(this.prototype, "loadingMore", [(0, _computed.alias)("topicList.loadingMore")]))();
    #loadingMore = (() => (dt7948.i(this, "loadingMore"), void 0))();
    wizardEventFieldId = null;
    composerEventPrefix = (() => wizardComposerEdtiorEventPrefix)();
    init() {
      super.init(...arguments);
      this.uppyComposerUpload.fileUploadElementId = `file-uploader-${(0, _string.dasherize)(this.field.id)}`;
      this.uppyComposerUpload.editorInputClass = `.${(0, _string.dasherize)(this.field.type)}-${(0, _string.dasherize)(this.field.id)} .d-editor-input`;
      this.uppyComposerUpload.composerModel = this.composer;
      if (!this.currentUser) {
        this.currentUser = {};
      }
    }
    allowedFileTypes() {
      return this.siteSettings.authorized_extensions.split("|").map(ext => "." + ext).join(",");
    }
    static #_3 = (() => dt7948.n(this.prototype, "allowedFileTypes", [_decorators.default]))();
    uploadIcon() {
      return (0, _uploads.uploadIcon)(false, this.siteSettings);
    }
    static #_4 = (() => dt7948.n(this.prototype, "uploadIcon", [(0, _decorators.default)()]))();
    _handleImageDeleteButtonClick() {
      this.session.set("wizardEventFieldId", this.field.id);
      super._handleImageDeleteButtonClick(...arguments);
    }
    static #_5 = (() => dt7948.n(this.prototype, "_handleImageDeleteButtonClick", [_decorators.bind]))();
    extraButtons(toolbar) {
      const component = this;
      if (this.allowUpload && this.uploadIcon) {
        toolbar.addButton({
          id: "upload",
          group: "insertions",
          icon: this.uploadIcon,
          title: "upload",
          sendAction: event => component.send("showUploadModal", event)
        });
      }
      toolbar.addButton({
        id: "link",
        icon: "link",
        group: "insertions",
        shortcut: "K",
        trimLeading: true,
        unshift: true,
        sendAction: event => component.send("showLinkModal", event)
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "extraButtons", [_object.action]))();
    showLinkModal(toolbarEvent) {
      let linkText = "";
      this._lastSel = toolbarEvent.selected;
      if (this._lastSel) {
        linkText = this._lastSel.value;
      }
      this.modal.show(_insertHyperlink.default, {
        model: {
          linkText,
          toolbarEvent
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "showLinkModal", [_object.action]))();
    showUploadModal() {
      this.session.set("wizardEventFieldId", this.field.id);
      document.getElementById(this.uppyComposerUpload.fileUploadElementId).click();
    }
    static #_8 = (() => dt7948.n(this.prototype, "showUploadModal", [_object.action]))();
    _uploadDropTargetOptions() {
      return {
        target: this.element,
        onDrop: () => {
          this.session.set("wizardEventFieldId", this.field.id);
        }
      };
    }
  }
  _exports.default = CustomWizardComposerEditor;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CustomWizardComposerEditor);
});