define("discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-custom-fields", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-wizard-controls">
    <h3>{{i18n "admin.wizard.custom_field.nav_label"}}</h3>
  
    <div class="buttons">
      {{d-button
        label="admin.wizard.custom_field.add"
        icon="plus"
        action=(action "addField")
      }}
    </div>
  </div>
  
  {{wizard-message
    key=this.messageKey
    opts=this.messageOpts
    type=this.messageType
    url=this.documentationUrl
    component="custom_fields"
  }}
  
  <div class="admin-wizard-container">
    {{#if this.customFields}}
      <table>
        <thead>
          <tr>
            {{#each this.fieldKeys as |key|}}
              <th>{{i18n (concat "admin.wizard.custom_field." key ".label")}}</th>
            {{/each}}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {{#each this.customFields as |field|}}
            {{custom-field-input
              field=field
              removeField=(action "removeField")
              saveField=(action "saveField")
            }}
          {{/each}}
        </tbody>
      </table>
    {{/if}}
  </div>
  */
  {
    "id": "mYMXB5RQ",
    "block": "[[[10,0],[14,0,\"admin-wizard-controls\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"admin.wizard.custom_field.nav_label\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"buttons\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"label\",\"icon\",\"action\"],[\"admin.wizard.custom_field.add\",\"plus\",[28,[37,2],[[30,0],\"addField\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"key\",\"opts\",\"type\",\"url\",\"component\"],[[30,0,[\"messageKey\"]],[30,0,[\"messageOpts\"]],[30,0,[\"messageType\"]],[30,0,[\"documentationUrl\"]],\"custom_fields\"]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-wizard-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"customFields\"]],[[[1,\"    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"fieldKeys\"]]],null]],null],null,[[[1,\"            \"],[10,\"th\"],[12],[1,[28,[35,0],[[28,[37,7],[\"admin.wizard.custom_field.\",[30,1],\".label\"],null]],null]],[13],[1,\"\\n\"]],[1]],null],[1,\"          \"],[10,\"th\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"customFields\"]]],null]],null],null,[[[1,\"          \"],[1,[28,[35,8],null,[[\"field\",\"removeField\",\"saveField\"],[[30,2],[28,[37,2],[[30,0],\"removeField\"],null],[28,[37,2],[[30,0],\"saveField\"],null]]]]],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"key\",\"field\"],false,[\"i18n\",\"d-button\",\"action\",\"wizard-message\",\"if\",\"each\",\"-track-array\",\"concat\",\"custom-field-input\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-custom-fields.hbs",
    "isStrictMode": false
  });
});