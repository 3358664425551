define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-connector", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "I18n", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", "@ember/template-factory"], function (_exports, _component, _object, _computed, _runloop, _I18n, _wizardMapper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasMultiple}}
    {{combo-box
      value=this.connector
      content=this.connectors
      onChange=(action "changeConnector")
    }}
  {{else}}
    {{#if this.connector}}
      <span class="connector-single">
        {{this.connectorLabel}}
      </span>
    {{/if}}
  {{/if}}
  */
  {
    "id": "ZURkUfPY",
    "block": "[[[41,[30,0,[\"hasMultiple\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"value\",\"content\",\"onChange\"],[[30,0,[\"connector\"]],[30,0,[\"connectors\"]],[28,[37,2],[[30,0],\"changeConnector\"],null]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"connector\"]],[[[1,\"    \"],[10,1],[14,0,\"connector-single\"],[12],[1,\"\\n      \"],[1,[30,0,[\"connectorLabel\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"combo-box\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-connector.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    classNameBindings: [":mapper-connector", ":mapper-block", "hasMultiple::single"],
    hasMultiple: (0, _computed.gt)("connectors.length", 1),
    connectorLabel: (0, _object.computed)(function () {
      let key = this.connector;
      let path = this.inputTypes ? `input.${key}.name` : `connector.${key}`;
      return _I18n.default.t(`admin.wizard.${path}`);
    }),
    didReceiveAttrs() {
      this._super();
      if (!this.connector) {
        (0, _runloop.later)(() => {
          this.set("connector", (0, _wizardMapper.defaultConnector)(this.connectorType, this.inputType, this.options));
        });
      }
    },
    actions: {
      changeConnector(value) {
        this.set("connector", value);
        this.onUpdate("connector", this.connectorType);
      }
    }
  }));
});