define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-pair", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", "@ember/template-factory"], function (_exports, _component, _object, _computed, _wizardMapper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="key mapper-block">
    {{wizard-mapper-selector
      selectorType="key"
      inputType=this.inputType
      value=this.pair.key
      activeType=this.pair.key_type
      options=this.options
      onUpdate=this.onUpdate
    }}
  </div>
  
  {{wizard-mapper-connector
    connector=this.pair.connector
    connectors=this.connectors
    connectorType="pair"
    inputType=this.inputType
    options=this.options
    onUpdate=this.onUpdate
  }}
  
  <div class="value mapper-block">
    {{wizard-mapper-selector
      selectorType="value"
      inputType=this.inputType
      value=this.pair.value
      activeType=this.pair.value_type
      options=this.options
      onUpdate=this.onUpdate
      connector=this.pair.connector
    }}
  </div>
  
  {{#if this.showJoin}}
    <span class="join-pair">&</span>
  {{/if}}
  
  {{#if this.showRemove}}
    <a
      role="button"
      {{action this.removePair this.pair}}
      class="remove-pair"
    >{{d-icon "times"}}</a>
  {{/if}}
  */
  {
    "id": "RK0K+qh3",
    "block": "[[[10,0],[14,0,\"key mapper-block\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"selectorType\",\"inputType\",\"value\",\"activeType\",\"options\",\"onUpdate\"],[\"key\",[30,0,[\"inputType\"]],[30,0,[\"pair\",\"key\"]],[30,0,[\"pair\",\"key_type\"]],[30,0,[\"options\"]],[30,0,[\"onUpdate\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"connector\",\"connectors\",\"connectorType\",\"inputType\",\"options\",\"onUpdate\"],[[30,0,[\"pair\",\"connector\"]],[30,0,[\"connectors\"]],\"pair\",[30,0,[\"inputType\"]],[30,0,[\"options\"]],[30,0,[\"onUpdate\"]]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"value mapper-block\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"selectorType\",\"inputType\",\"value\",\"activeType\",\"options\",\"onUpdate\",\"connector\"],[\"value\",[30,0,[\"inputType\"]],[30,0,[\"pair\",\"value\"]],[30,0,[\"pair\",\"value_type\"]],[30,0,[\"options\"]],[30,0,[\"onUpdate\"]],[30,0,[\"pair\",\"connector\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showJoin\"]],[[[1,\"  \"],[10,1],[14,0,\"join-pair\"],[12],[1,\"&\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showRemove\"]],[[[1,\"  \"],[11,3],[24,\"role\",\"button\"],[24,0,\"remove-pair\"],[4,[38,3],[[30,0],[30,0,[\"removePair\"]],[30,0,[\"pair\"]]],null],[12],[1,[28,[35,4],[\"times\"],null]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"wizard-mapper-selector\",\"wizard-mapper-connector\",\"if\",\"action\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-pair.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    classNameBindings: [":mapper-pair", "hasConnector::no-connector"],
    firstPair: (0, _computed.gt)("pair.index", 0),
    showRemove: (0, _computed.alias)("firstPair"),
    showJoin: (0, _object.computed)("pair.pairCount", function () {
      return this.pair.index < this.pair.pairCount - 1;
    }),
    connectors: (0, _object.computed)(function () {
      return (0, _wizardMapper.connectorContent)("pair", this.inputType, this.options);
    })
  }));
});