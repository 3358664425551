define("discourse/plugins/discourse-custom-wizard/discourse/components/similar-topics-validator", ["exports", "@ember/component", "@ember/array", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/string", "discourse/helpers/category-link", "discourse-common/lib/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/components/validator", "@ember/template-factory"], function (_exports, _component, _array, _object, _computed, _runloop, _string, _categoryLink, _object2, _decorators, _validator, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class={{this.currentStateClass}}>
    {{#if this.currentState}}
      {{#if this.insufficientCharactersCategories}}
        {{html-safe (i18n this.currentStateKey catLinks=this.catLinks)}}
      {{else}}
        {{i18n this.currentStateKey}}
      {{/if}}
    {{/if}}
  </label>
  
  {{#if this.showSimilarTopics}}
    {{custom-wizard-similar-topics topics=this.similarTopics}}
  {{/if}}
  */
  {
    "id": "MW3HqiL/",
    "block": "[[[10,\"label\"],[15,0,[30,0,[\"currentStateClass\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"currentState\"]],[[[41,[30,0,[\"insufficientCharactersCategories\"]],[[[1,\"      \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"currentStateKey\"]]],[[\"catLinks\"],[[30,0,[\"catLinks\"]]]]]],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,2],[[30,0,[\"currentStateKey\"]]],null]],[1,\"\\n\"]],[]]]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showSimilarTopics\"]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"topics\"],[[30,0,[\"similarTopics\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"html-safe\",\"i18n\",\"custom-wizard-similar-topics\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/similar-topics-validator.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _validator.default.extend(dt7948.p({
    classNames: ["similar-topics-validator"],
    similarTopics: null,
    hasInput: (0, _computed.notEmpty)("field.value"),
    hasSimilarTopics: (0, _computed.notEmpty)("similarTopics"),
    hasNotSearched: (0, _computed.equal)("similarTopics", null),
    noSimilarTopics: (0, _object.computed)("similarTopics", function () {
      return this.similarTopics !== null && this.similarTopics.length === 0;
    }),
    showSimilarTopics: (0, _object.computed)("typing", "hasSimilarTopics", function () {
      return this.hasSimilarTopics && !this.typing;
    }),
    showNoSimilarTopics: (0, _object.computed)("typing", "noSimilarTopics", function () {
      return this.noSimilarTopics && !this.typing;
    }),
    hasValidationCategories: (0, _computed.notEmpty)("validationCategories"),
    insufficientCharacters: (0, _object.computed)("typing", "field.value", function () {
      return this.hasInput && this.field.value.length < 5 && !this.typing;
    }),
    insufficientCharactersCategories: (0, _computed.and)("insufficientCharacters", "hasValidationCategories"),
    validationCategories(categoryIds) {
      if (categoryIds) {
        return categoryIds.map(id => this.site.categoriesById[id]);
      }
      return (0, _array.A)();
    },
    catLinks(categories) {
      return categories.map(category => (0, _categoryLink.categoryBadgeHTML)(category)).join("");
    },
    currentState(loading, showSimilarTopics, showNoSimilarTopics, insufficientCharacters, insufficientCharactersCategories) {
      switch (true) {
        case loading:
          return "loading";
        case showSimilarTopics:
          return "results";
        case showNoSimilarTopics:
          return "no_results";
        case insufficientCharactersCategories:
          return "insufficient_characters_categories";
        case insufficientCharacters:
          return "insufficient_characters";
        default:
          return false;
      }
    },
    currentStateClass(currentState) {
      if (currentState) {
        return `similar-topics-${(0, _string.dasherize)(currentState)}`;
      }
      return "similar-topics";
    },
    currentStateKey(currentState) {
      if (currentState) {
        return `realtime_validations.similar_topics.${currentState}`;
      }
      return false;
    },
    validate() {},
    customValidate() {
      const field = this.field;
      if (!field.value) {
        this.set("similarTopics", null);
        return;
      }
      const value = field.value;
      this.set("typing", true);
      const lastKeyUp = new Date();
      this._lastKeyUp = lastKeyUp;

      // One second from now, check to see if the last key was hit when
      // we recorded it. If it was, the user paused typing.
      (0, _runloop.cancel)(this._lastKeyTimeout);
      this._lastKeyTimeout = (0, _runloop.later)(() => {
        if (lastKeyUp !== this._lastKeyUp) {
          return;
        }
        this.set("typing", false);
        if (value && value.length < 5) {
          this.set("similarTopics", null);
          return;
        }
        this.updateSimilarTopics();
      }, 1000);
    },
    updateSimilarTopics() {
      this.set("similarTopics", null);
      this.set("updating", true);
      this.backendValidate({
        title: this.get("field.value"),
        categories: this.get("validation.categories"),
        time_unit: this.get("validation.time_unit"),
        time_n_value: this.get("validation.time_n_value")
      }).then(result => {
        const similarTopics = (0, _array.A)((0, _object2.deepMerge)(result["topics"], result["similar_topics"]));
        similarTopics.forEach(function (topic, index) {
          similarTopics[index] = _object.default.create(topic);
        });
        this.set("similarTopics", similarTopics);
      }).finally(() => this.set("updating", false));
    },
    actions: {
      closeMessage() {
        this.set("showMessage", false);
      }
    }
  }, [["method", "validationCategories", [(0, _decorators.default)("validation.categories")]], ["method", "catLinks", [(0, _decorators.default)("validationCategories")]], ["method", "currentState", [(0, _decorators.default)("loading", "showSimilarTopics", "showNoSimilarTopics", "insufficientCharacters", "insufficientCharactersCategories")]], ["method", "currentStateClass", [(0, _decorators.default)("currentState")]], ["method", "currentStateKey", [(0, _decorators.default)("currentState")]], ["method", "customValidate", [(0, _decorators.observes)("field.value")]]])));
});